import { decodeHtml } from '../../utils/utils';

export default class License {
  static parseResults = (data) => {
    let isArr = data instanceof Array;
    if (isArr) {
      let res = [];
      data.forEach(function (license, index) {
        res.push(License.parseObj(license));
      });
      return res;
    } else {
      let license = data;
      return this.parseObj(license);
    }
  };

  static parseObj = (license) => {
    let firstName = license.ownerFirstName || '';
    let lastName = license.ownerLastName || '';

    Object.assign(license, { ownerFirstAndLastName: firstName + ' ' + lastName });
    Object.assign(license, { tepSelectedOptions: license.tepSelectedOptions });

    license.administrators &&
      license.administrators.forEach(function (user, index) {
        Object.assign(user, { firstAndLastName: user.firstName + ' ' + user.lastName });
      });
    license.contacts &&
      license.contacts.forEach(function (contact, index) {
        Object.assign(contact, { firstAndLastName: contact.firstName + ' ' + contact.lastName });
      });

    // handle data with embedded html entities
    Object.assign(license, { orgName: decodeHtml(license.orgName) });

    license.products &&
      license.products.forEach(function (product, index) {
        if (product.name) {
          Object.assign(product, { name: decodeHtml(product.name) });
        }
      });
    firstName = license.salesRepFirstName || '';
    lastName = license.salesRepLastName || '';
    Object.assign(license, { salesRepFirstAndLastName: firstName + ' ' + lastName });

    return license;
  };
}
