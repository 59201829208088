import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import Banner from './Banner';
import Button from '@material-ui/core/Button';
import Config from '../../utils/config';
import { signOut } from '../../utils/authentication';

const config = new Config();

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  errorBox: {
    marginTop: theme.spacing(12),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
  },
  heading: {
    fontSize: '20px',
    fontWeight: 400,
    color: theme.palette.error.main,
    display: 'flex',
  },
  heading1: {
    fontSize: '20px',
    fontWeight: 400,
    display: 'flex',
  },
  icon: {
    marginRight: '6px',
  },
});

const ErrorPage = ({ classes, errMsg, instance, invalidToken }) => {
  const onSignOut = () => {
    signOut(instance);
  };

  const renderUnauthorizedMessage = () => {
    return (
      <React.Fragment>
        <div className={classes.heading}>
          <ErrorOutlineRoundedIcon aria-hidden={true} className={classes.icon} />
          <span>Unauthorized</span>
        </div>
        <p>Sorry, you are not authorized to access this application.</p>
        <p>
          If you feel this is in error, or would like to gain access, you can inquire in the{' '}
          <a
            href={config.get('FSAT_TEAMS_ROOM_URL')}
            target='_blank'
            title='opens in a new tab or window'
            rel='noopener noreferrer'>
            Support Dashboard Team Help channel
          </a>
          .
        </p>
      </React.Fragment>
    );
  };

  const renderErrorMessage = () => {
    return (
      <React.Fragment>
        <div className={classes.heading}>
          <ErrorOutlineRoundedIcon aria-hidden={true} className={classes.icon} />
          <span>Error</span>
        </div>
        <p>{errMsg}</p>
        <p>
          If the error is persistent, you can inquire in the{' '}
          <a
            href={config.get('FSAT_TEAMS_ROOM_URL')}
            target='_blank'
            title='opens in a new tab or window'
            rel='noopener noreferrer'>
            Support Dashboard Team Help channel
          </a>
          .
        </p>
      </React.Fragment>
    );
  };

  const renderSessionExpired = () => {
    return (
      <React.Fragment>
        <div className={classes.heading1}>
          <span>Your session has expired.</span>
        </div>
        <Button color='primary' onClick={onSignOut}>
          Sign in again
        </Button>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <Banner instance={instance} />
      <Container maxWidth='md'>
        <div className={classes.errorBox}>
          {invalidToken
            ? renderSessionExpired()
            : errMsg
            ? renderErrorMessage()
            : renderUnauthorizedMessage()}
        </div>
      </Container>
    </React.Fragment>
  );
};

ErrorPage.propTypes = {
  errMsg: PropTypes.string,
  invalidToken: PropTypes.bool,
  instance: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(ErrorPage);
