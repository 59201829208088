import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ErrorPage from './components/pages/ErrorPage';
import Main from './components/pages/Main';
import { AppProvider } from './components/context/appContext';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#047a9c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ffb81c',
      contrastText: '#000',
    },
    tertiary: {
      main: '#D4EAE4',
      contrastText: '#000',
    },
    background: {
      default: '#F5F5F5',
    },
  },
  zIndex: {
    drawer: 5,
  },
});

theme.overrides = {
  MuiButton: {
    root: {
      textTransform: 'none',
    },
  },
};

const App = ({ authorized, instance, errMsg, invalidToken }) => {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <React.Fragment>
          <CssBaseline />
          {authorized ? (
            <BrowserRouter>
              <Main instance={instance} />
            </BrowserRouter>
          ) : (
            <ErrorPage instance={instance} invalidToken={invalidToken} errMsg={errMsg} />
          )}
        </React.Fragment>
      </AppProvider>
    </ThemeProvider>
  );
};

App.propTypes = {
  authorized: PropTypes.bool.isRequired,
  instance: PropTypes.object.isRequired,
  invalidToken: PropTypes.bool,
  errMsg: PropTypes.string,
};

export default withStyles({}, { withTheme: true })(App);
