import { commaSeparate } from '../../utils/utils';

export default class Course {
  static parseResults = (data) => {
    let isArr = data instanceof Array;
    if (isArr) {
      let res = [];
      data.forEach(function (course, index) {
        res.push(Course.parseObj(course));
      });
      return res;
    } else {
      let course = data;
      return this.parseObj(course);
    }
  };

  static parseObj = (course) => {
    let instructors = [];
    if (course.instructors) {
      let emails = [];
      course.instructors.forEach(function (inst, index) {
        instructors.push(inst.firstName + ' ' + inst.lastName);
        emails.push(inst.email);
      });
      Object.assign(course, { instructorEmail: commaSeparate(emails) });
    }
    // if no instructors we'll get 'null'. don't want the same for secondary field, emails
    Object.assign(course, { instructorFullName: commaSeparate(instructors) });

    let lmsAry = [];
    if (course.course && course.course.externalSystemReferences) {
      course.course.externalSystemReferences.forEach(function (externalSystemReference, index) {
        if (externalSystemReference.partnerName != null) {
          lmsAry.push(externalSystemReference.partnerName);
        }
      });
    }
    if (lmsAry.length === 0) {
      lmsAry.push('N/A');
    }
    Object.assign(course, { lmsString: commaSeparate(lmsAry) });

    let status = (course.sectionStatus && course.sectionStatus.toUpperCase()) || 'Status: N/A';
    Object.assign(course, { status: status });
    const lmsOrgId = !course.lmsCourse || !(course.lmsCourse.length > 0) || !course.lmsCourse[0] ? null :
      course.organizationId !== course.lmsCourse[0].orgId ? course.lmsCourse[0].orgId : null;
    Object.assign(course, { lmsOrgId });
    return course;
  };
}
