
let _userPermissions = [];

export const initPermissions = perms => {
  _userPermissions = perms;
}

export const canView = perm => {
  return _userPermissions.indexOf(perm+'-read') >= 0 ||
         _userPermissions.indexOf(perm+'-edit') >= 0;
}

export const canEdit = perm => {
  return _userPermissions.indexOf(perm+'-edit') >= 0;
}

export const canDelete = perm => {
  return _userPermissions.indexOf(perm+'-delete') >= 0;
}

export const canCreate = perm => {
  return _userPermissions.indexOf(perm+'-create') >= 0;
}

export const canAccessPermissions = () =>  {
  return _userPermissions.some(x => ['agentRoles-read', 'agentRoles-edit', 'rolePermissions-read', 'rolePermissions-edit'].indexOf(x) >= 0);
}