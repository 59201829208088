
import { valueFrom, setObjectValue } from './utils';

const defaultConfig = {
  U42: {
    TOKEN: '',
    AUTHORIZED: false,
    INTERVAL: 300000,  // milliseconds
    UID: '',
    TOKEN_EXPIRES_ON: '',
  },
  IES: {
    IMPERSONATE: {
      dev: 'https://login-stg.pearson.com/v1/piapi-int/login/impersonatebyotp',
      qa: 'https://login-qa.pearson.com/v1/piapi-test/login/impersonatebyotp',
      stg: 'https://login-stg.pearson.com/v1/piapi-int/login/impersonatebyotp',
      ppe: 'https://login-ppe.pearson.com/v1/piapi-pqa/login/impersonatebyotp',
      prod: 'https://login.pearson.com/v1/piapi/login/impersonatebyotp',
      local: 'https://login-stg.pearson.com/v1/piapi-int/login/impersonatebyotp',
    },
  },
  PHONE_COMPONENT_COUNTRIES: ["US","AU","AT","BH","BE","BR","KH","CA","CO","DK","EC","FI","FR","DE","GT","HK","IN","ID","IE","IT","JP","JO","KE","KW","LU","MO","MY","MX","MM","NL","NO","PH","QA","SA","SG","KR","SE","TH","AE","GB","UZ","VN"],  
  FSAT_TEAMS_ROOM_URL: 'https://teams.microsoft.com/l/channel/19%3a3ff95f1295ef430d8286b2bf0a198616%40thread.skype/General?groupId=968fa2b0-b8c9-4978-b399-3aac1b6765cc&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b',
};

export default class Config {
  constructor() {
    if (!this.config) {
      this.config = window.UI_CONFIG || defaultConfig;
    }
  }

  get(key, def) {
    return valueFrom(key, this.config, def);
  }

  set(key, value) {
    setObjectValue(this.config, key, value);
    return this;
  }
}
