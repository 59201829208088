import { formatPhoneNumber } from 'react-phone-number-input';
import { parsePhoneNumber } from 'react-phone-number-input';

export default class Identity {
  static parseResults = (data) => {
    let res = [];
    let numFiltered = 0;
    data.forEach(function (user) {
      if (user.identityError) {
        numFiltered++;
      } else {
        res.push(Identity.parseObj(user));
      }
    });
    return [res, numFiltered];
  };

  static parseObj = (user) => {
    let firstName = user.firstName || '';
    let lastName = user.lastName || '';
    Object.assign(user, { firstAndLastName: firstName + ' ' + lastName });

    Object.assign(user, { fullPhoneNumber: '' });
    let number =
      user.phoneCountryCode && user.phoneNumber
        ? '+' + user.phoneCountryCode + user.phoneNumber
        : '';
    if (number) {
      let phoneNumber = parsePhoneNumber(number);
      if (phoneNumber) {
        Object.assign(user, {
          fullPhoneNumber: '+' + phoneNumber.countryCallingCode + ' ' + formatPhoneNumber(number),
        });
      }
    }

    // need empty string for correct sorting
    let lastLoginSuccess = user.lastLoginSuccess || '';
    Object.assign(user, { lastLoginSuccess: lastLoginSuccess });

    let lmsPairings = user.lmsPairings && user.lmsPairings.length > 0 ? user.lmsPairings : null;
    Object.assign(user, { federatedAccountsStr: lmsPairings ? lmsPairings.length : 'No' });
    Object.assign(user, {
      selfIdentifiedInstructor: user.isSelfIdentifiedInstructor ? 'Yes' : 'No',
    });
    Object.assign(user, {
      validatedRevelInstructor: user.isValidatedRevelInstructor ? 'Yes' : 'No',
    });
    Object.assign(user, {
      learner: user.isLearner ? 'Yes' : 'No',
    });
    return user;
  };
}
