import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import logo from '../../images/logo.svg';
import { signOut } from '../../utils/authentication';

const styles = (theme) => ({
  root: {
    // flexGrow: 1,
  },
  banner: {
    color: '#000',
    backgroundColor: '#fff',
    padding: '2px 0',
    boxShadow: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
});

const Banner = (props) => {
  const { classes } = props;
  const onSignOut = () => {
    signOut(props.instance);
  };
  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.banner}>
        <Toolbar variant='dense'>
          <img src={logo} alt='pearson logo' />
          <Typography variant='h6' className={classes.title}>
            Pearson Support Dashboard
          </Typography>
          <Button color='primary' onClick={onSignOut}>
            Sign out
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

Banner.propTypes = {
  instance: PropTypes.object,
};

export default withStyles(styles)(Banner);
