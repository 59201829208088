import React, { lazy, Suspense, useReducer, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { canView } from '../../utils/permissions.js';
import { createQueryString } from '../../utils/utils.js';
import { recordAuditEvent } from '../../utils/utils.js';
import { validateUser } from '../../utils/authentication.js';
import { loadCountries } from '../../utils/countries.js';
import Course from '../models/Course.js';
import License from '../models/License.js';
import Identity from '../models/Identity.js';
import {
  userSearchCriteria,
  courseSearchCriteria,
  productSearchCriteria,
  licenseSearchCriteria,
  orderSearchCriteria,
  userPagination,
  coursePagination,
  productPagination,
  accessCodePagination,
  licensePagination,
  orderPagination,
  organizationPagination,
  rowsPerPageOptions,
  createLicenseDefaults,
} from '../../utils/data.js';
const LeftNav = lazy(() => import('./LeftNav.js'));
const PersonSearch = lazy(() => import('../person/PersonSearch.js'));
const PersonDetails = lazy(() => import('../person/PersonDetails.js'));
const CourseSearch = lazy(() => import('../course/CourseSearch.js'));
const CourseDetails = lazy(() => import('../course/CourseDetails.js'));
const ProductSearch = lazy(() => import('../product/ProductSearch.js'));
const ProductDetails = lazy(() => import('../product/ProductDetails.js'));
const AccessCodeSearch = lazy(() => import('../accessCode/AccessCodeSearch.js'));
const AccessCodeDetails = lazy(() => import('../accessCode/AccessCodeDetails.js'));
const LicenseSearch = lazy(() => import('../license/LicenseSearch.js'));
const LicenseDetails = lazy(() => import('../license/LicenseDetails.js'));
const CreateLicense = lazy(() => import('../license/CreateLicense.js'));
const OrderSearch = lazy(() => import('../order/OrderSearch.js'));
const OrderDetails = lazy(() => import('../order/OrderDetails.js'));
const OrganizationSearch = lazy(() => import('../organization/OrganizationSearch.js'));
const OrganizationDetails = lazy(() => import('../organization/OrganizationDetails.js'));
const AdminDashboard = lazy(() => import('../admin/AdminDashboard.js'));
const PageNotFound = lazy(() => import('../common/PageNotFound.js'));
const Banner = lazy(() => import('./Banner.js'));
const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const initialState = {
  // original main state
  user: null,
  course: null,
  product: null,
  accessCode: null,
  license: null,
  order: null,
  organization: null,

  // new state for details pages
  courseDetails: {
    activeTab: 0,
  },
  productDetails: {
    activeTab: 0,
  },
  accessCodeDetails: {
    activeTab: 0,
  },
  licenseDetails: {
    activeTab: 0,
  },
  orderDetail: {
    activeTab: 0,
  },

  // state from ToolsView (now PersonDetails)
  selectedTools: [],
  savedSelectedTools: [],
  panelMaximized: false,
  productToSearch: '',

  // state from SearchView
  showBackdrop: false,
  userSearch: {
    init: true,
    spinner: false,
    results: [],
    totalElements: 0,
    rowSelected: '',
    error: '',
    softError: '',
    searchBy: 'email',
    searchTermLabel: 'Email address',
    searchTermHelperText: 'e.g. usersemail@uni.edu',
    basicSearchField: { value: 'email' },
    advancedSearchFields: [
      { value: 'email' },
      { value: 'phone' },
      { value: 'partialUserName' },
      { value: 'name' },
    ],
    isAdvancedSearch: false,
    pagination: userPagination,
  },
  courseSearch: {
    init: true,
    spinner: false,
    results: [],
    rowSelected: '',
    totalElements: 0, // backend pagination
    error: '',
    searchBy: 'courseId',
    searchTerm: '',
    searchTermLabel: '',
    searchTermHelperText: '',
    pagination: coursePagination,
  },
  productSearch: {
    init: true,
    spinner: false,
    results: [],
    rowSelected: '',
    error: '',
    searchBy: 'id',
    searchTerm: '',
    searchTermLabel: '',
    searchTermHelperText: '',
    pagination: productPagination,
  },
  accessCodeSearch: {
    init: true,
    spinner: false,
    results: [],
    rowSelected: '',
    error: '',
    accessCode: '',
    pagination: accessCodePagination,
  },
  licenseSearch: {
    init: true,
    spinner: false,
    results: [],
    rowSelected: '',
    totalElements: 0, // backend pagination
    error: '',
    searchBy: 'id',
    searchTerm: '',
    searchTermLabel: '',
    searchTermHelperText: '',
    pagination: licensePagination,
    userSearchResults: [],
    userSearchResultsDialogOpen: false,
    payload: {
      ownerId: '',
      page: '',
      size: '',
    },
  },
  createLicense: Object.assign({}, createLicenseDefaults), // shallow copy

  orderSearch: {
    init: true,
    spinner: false,
    results: [],
    rowSelected: '',
    error: '',
    searchBy: 'orderId',
    searchTerm: '',
    searchTermLabel: '',
    searchTermHelperText: '',
    pagination: orderPagination,
  },
  organizationSearch: {
    init: true,
    spinner: false,
    results: [],
    rowSelected: '',
    error: '',
    searchTerm: '',
    searchTermLabel: '',
    searchTermHelperText: '',
    pagination: organizationPagination,
  },
  admin: {
    activeTab: 0,
  },
  agentList: {
    searchTerm: '',
    results: [],
    totalElements: 0,
    loading: true,
    pagination: {
      order: 'asc',
      orderBy: 'agentName',
      page: 0,
      rowsPerPage: rowsPerPageOptions[1],
    },
  },
  OrgDetails: {
    activeTab: 0,
  },
  userAudits: {
    searchTerm: '',
    startDate: moment.utc().subtract(1, 'days'),
    endDate: moment.utc().add(4, 'hours'),
  },
  userRoles: {
    searchTerm: '',
  },
};

const mainReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_BACKDROP':
      return {
        ...state,
        showBackdrop: action.payload,
      };
    case 'USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SELECTED_USER':
      return {
        ...state,
        user: action.payload.user,
        userSearch: { ...state.userSearch, rowSelected: action.payload.rowSelected },
      };
    case 'UPDATE_USER':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case 'SELECTED_COURSE':
      return {
        ...state,
        course: action.payload.course,
        courseSearch: { ...state.courseSearch, rowSelected: action.payload.rowSelected },
      };
    case 'UPDATE_COURSE':
      return {
        ...state,
        course: {
          ...state.course,
          ...action.payload,
        },
      };
    case 'SELECTED_PRODUCT':
      return {
        ...state,
        product: action.payload.product,
        productSearch: { ...state.productSearch, rowSelected: action.payload.rowSelected },
      };
    case 'UPDATE_PRODUCT':
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
        },
      };
    case 'SELECTED_ACCESS_CODE':
      return {
        ...state,
        accessCode: action.payload.accessCode,
        accessCodeSearch: { ...state.accessCodeSearch, rowSelected: action.payload.rowSelected },
      };

    case 'UPDATE_ACCESS_CODE':
      return {
        ...state,
        accessCode: {
          ...state.accessCode,
          ...action.payload,
        },
      };
    case 'SELECTED_LICENSE':
      return {
        ...state,
        license: action.payload.license,
        licenseSearch: { ...state.licenseSearch, rowSelected: action.payload.rowSelected },
      };
    case 'UPDATE_LICENSE':
      return {
        ...state,
        license: {
          ...state.license,
          ...action.payload,
        },
      };
    case 'SELECTED_ORDER':
      return {
        ...state,
        order: action.payload.order,
        orderSearch: { ...state.orderSearch, rowSelected: action.payload.rowSelected },
      };
    case 'UPDATE_ORDER':
      return {
        ...state,
        order: {
          ...state.order,
          ...action.payload,
        },
      };
    case 'SELECTED_ORGANIZATION':
      return {
        ...state,
        organization: action.payload.organization,
        organizationSearch: {
          ...state.organizationSearch,
          rowSelected: action.payload.rowSelected,
        },
      };
    case 'UPDATE_ORGANIZATION':
      return {
        ...state,
        organization: {
          ...state.organization,
          ...action.payload,
        },
      };
    case 'SELECTED_TOOLS':
      return {
        ...state,
        selectedTools: action.payload,
      };
    case 'SAVED_SELECTED_TOOLS':
      return {
        ...state,
        savedSelectedTools: action.payload,
      };
    case 'PANEL_MAXIMIZED':
      return {
        ...state,
        panelMaximized: action.payload,
      };
    case 'PRODUCT_TO_SEARCH':
      return {
        ...state,
        productToSearch: action.payload,
      };
    case 'UPDATE_USER_SEARCH_STATE':
      return {
        ...state,
        userSearch: {
          ...state.userSearch,
          ...action.payload,
        },
      };
    case 'USER_SEARCHING':
      return {
        ...state,
        userSearch: {
          ...state.userSearch,
          init: false,
          spinner: true,
          results: [],
          error: '',
          softError: '',
          ...action.payload,
        },
      };
    case 'USER_SEARCH_RESULTS_CHANGE':
      return {
        ...state,
        userSearch: {
          ...state.userSearch,
          init: false,
          spinner: false,
          results: action.payload.results,
          totalElements: action.payload.totalElements,
          error: '',
          softError: action.payload.softError ? action.payload.softError : '',
        },
      };
    case 'USER_DIRECT_HIT':
      return {
        ...state,
        userSearch: {
          ...state.userSearch,
          init: true,
          spinner: false,
          results: [],
          error: '',
          softError: '',
        },
      };
    case 'USER_SEARCH_ERROR':
      return {
        ...state,
        userSearch: {
          ...state.userSearch,
          init: false,
          spinner: false,
          results: [],
          error: action.payload,
          softError: '',
        },
      };
    case 'USER_PAGINATION_CHANGE':
      return {
        ...state,
        userSearch: {
          ...state.userSearch,
          pagination: {
            order: action.payload.order,
            orderBy: action.payload.orderBy,
            page: action.payload.page,
            rowsPerPage: action.payload.rowsPerPage,
          },
        },
      };
    case 'TRIGGER_USER_SEARCH':
      if (action.payload.hasOwnProperty('searchBy')) {
        return {
          ...state,
          userSearch: {
            ...state.userSearch,
            init: false,
            spinner: true,
            results: [],
            error: '',
            searchBy: action.payload.searchBy,
            searchTerm: action.payload.searchTerm,
            searchTermLabel: action.payload.searchTermLabel,
            searchTermHelperText: action.payload.searchTermHelperText,
            pagination: {
              ...state.userSearch.pagination,
              page: 0,
            },
          },
        };
      } else {
        return {
          ...state,
          userSearch: {
            ...state.userSearch,
            pagination: {
              order: action.payload.order,
              orderBy: action.payload.orderBy,
              page: action.payload.page,
              rowsPerPage: action.payload.rowsPerPage,
            },
          },
        };
      }
    case 'TOGGLE_ADVANCED_USER_SEARCH':
      return {
        ...state,
        userSearch: {
          ...state.userSearch,
          isAdvancedSearch: !state.userSearch.isAdvancedSearch,
        },
      };
    case 'BASIC_SEARCH_FIELD':
      return {
        ...state,
        basicSearchField: action.payload,
      };
    case 'ADVANCE_SEARCH_FIELD':
      return {
        ...state,
        advancedSearchFields: action.payload,
      };
    case 'SET_COURSE_ACTIVE_TAB':
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          activeTab: action.payload,
        },
      };

    case 'UPDATE_COURSE_SEARCH_STATE':
      return {
        ...state,
        courseSearch: {
          ...state.courseSearch,
          ...action.payload,
        },
      };
    case 'COURSE_SEARCHING':
      return {
        ...state,
        courseSearch: {
          ...state.courseSearch,
          init: false,
          spinner: true,
          results: [],
          error: '',
          searchBy: action.payload.searchBy,
          searchTerm: action.payload.searchTerm,
          searchTermLabel: action.payload.searchTermLabel,
          searchTermHelperText: action.payload.searchTermHelperText,
          pagination: action.payload.pagination,
        },
      };
    case 'COURSE_SEARCH_RESULTS_CHANGE':
      return {
        ...state,
        courseSearch: {
          ...state.courseSearch,
          init: false,
          spinner: false,
          results: action.payload.results,
          totalElements: action.payload.totalElements,
          error: '',
        },
      };
    case 'COURSE_DIRECT_HIT':
      return {
        ...state,
        courseSearch: {
          ...state.courseSearch,
          init: true,
          spinner: false,
          results: [],
          error: '',
        },
      };
    case 'COURSE_SEARCH_ERROR':
      return {
        ...state,
        courseSearch: {
          ...state.courseSearch,
          init: false,
          spinner: false,
          results: [],
          error: action.payload,
        },
      };
    case 'COURSE_PAGINATION_CHANGE':
      return {
        ...state,
        courseSearch: {
          ...state.courseSearch,
          pagination: {
            order: action.payload.order,
            orderBy: action.payload.orderBy,
            page: action.payload.page,
            rowsPerPage: action.payload.rowsPerPage,
          },
        },
      };
    case 'TRIGGER_COURSE_SEARCH':
      if (action.payload.hasOwnProperty('searchBy')) {
        return {
          ...state,
          courseSearch: {
            ...state.courseSearch,
            init: false,
            spinner: true,
            results: [],
            error: '',
            searchBy: action.payload.searchBy,
            searchTerm: action.payload.searchTerm,
            searchTermLabel: action.payload.searchTermLabel,
            searchTermHelperText: action.payload.searchTermHelperText,
            pagination: {
              ...state.courseSearch.pagination,
              page: 0,
            },
          },
        };
      } else {
        return {
          ...state,
          courseSearch: {
            ...state.courseSearch,
            pagination: {
              order: action.payload.order,
              orderBy: action.payload.orderBy,
              page: action.payload.page,
              rowsPerPage: action.payload.rowsPerPage,
            },
          },
        };
      }
    case 'SET_PRODUCT_ACTIVE_TAB':
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          activeTab: action.payload,
        },
      };
    case 'UPDATE_PRODUCT_SEARCH_STATE':
      return {
        ...state,
        productSearch: {
          ...state.productSearch,
          ...action.payload,
        },
      };
    case 'PRODUCT_SEARCHING':
      return {
        ...state,
        productSearch: {
          ...state.productSearch,
          init: false,
          spinner: true,
          results: [],
          error: '',
          pagination: productPagination,
          ...action.payload,
        },
      };
    case 'PRODUCT_SEARCH_RESULTS_CHANGE':
      return {
        ...state,
        productSearch: {
          ...state.productSearch,
          init: false,
          spinner: false,
          results: action.payload,
          error: '',
        },
      };
    case 'PRODUCT_DIRECT_HIT':
      return {
        ...state,
        productSearch: {
          ...state.productSearch,
          init: true,
          spinner: false,
          results: [],
          error: '',
        },
      };
    case 'PRODUCT_SEARCH_ERROR':
      return {
        ...state,
        productSearch: {
          ...state.productSearch,
          init: false,
          spinner: false,
          results: [],
          error: action.payload,
        },
      };
    case 'PRODUCT_PAGINATION_CHANGE':
      return {
        ...state,
        productSearch: {
          ...state.productSearch,
          pagination: {
            order: action.payload.order,
            orderBy: action.payload.orderBy,
            page: action.payload.page,
            rowsPerPage: action.payload.rowsPerPage,
          },
        },
      };
    case 'SET_ACCESS_CODE_ACTIVE_TAB':
      return {
        ...state,
        accessCodeDetails: {
          ...state.accessCodeDetails,
          activeTab: action.payload,
        },
      };
    case 'ACCESS_CODE_SEARCHING':
      return {
        ...state,
        accessCodeSearch: {
          ...state.accessCodeSearch,
          init: false,
          spinner: true,
          results: [],
          error: '',
          accessCode: action.payload,
          pagination: accessCodePagination,
        },
      };
    case 'ACCESS_CODE_SEARCH_RESULTS_CHANGE':
      return {
        ...state,
        accessCodeSearch: {
          ...state.accessCodeSearch,
          init: false,
          spinner: false,
          results: action.payload,
          error: '',
        },
      };
    case 'ACCESS_CODE_DIRECT_HIT':
      return {
        ...state,
        accessCodeSearch: {
          ...state.accessCodeSearch,
          init: true,
          spinner: false,
          results: [],
          error: '',
        },
      };
    case 'ACCESS_CODE_SEARCH_ERROR':
      return {
        ...state,
        accessCodeSearch: {
          ...state.accessCodeSearch,
          init: false,
          spinner: false,
          results: [],
          error: action.payload,
        },
      };
    case 'ACCESS_CODE_PAGINATION_CHANGE':
      return {
        ...state,
        accessCodeSearch: {
          ...state.accessCodeSearch,
          pagination: {
            order: action.payload.order,
            orderBy: action.payload.orderBy,
            page: action.payload.page,
            rowsPerPage: action.payload.rowsPerPage,
          },
        },
      };
    case 'SET_LICENSE_ACTIVE_TAB':
      return {
        ...state,
        licenseDetails: {
          ...state.licenseDetails,
          activeTab: action.payload,
        },
      };
    case 'SET_ORG_ACTIVE_TAB':
      return {
        ...state,
        OrgDetails: {
          ...state.OrgDetails,
          activeTab: action.payload,
        },
      };
    case 'UPDATE_LICENSE_SEARCH_STATE':
      return {
        ...state,
        licenseSearch: {
          ...state.licenseSearch,
          ...action.payload,
        },
      };
    case 'LICENSE_PAYLOAD_FOUND':
      return {
        ...state,
        licenseSearch: {
          ...state.licenseSearch,
          payload: {
            ownerId: action.payload.results.ownerId,
            page: state.licenseSearch.pagination.page,
            size: state.licenseSearch.pagination.rowsPerPage,
          }          
        }
      }
    case 'LICENSE_SEARCHING':
      return {
        ...state,
        licenseSearch: {
          ...state.licenseSearch,
          init: false,
          spinner: true,
          results: [],
          error: '',
          pagination: licensePagination,
          userSearchResults: [],
          userSearchResultsDialogOpen: false,
          ...action.payload,
        },
      };
    case 'LICENSE_SEARCH_RESULTS_CHANGE':
      return {
        ...state,
        licenseSearch: {
          ...state.licenseSearch,
          init: false,
          spinner: false,
          results: action.payload.results,
          totalElements: action.payload.totalElements,
          error: '',
        },
      };
    case 'LICENSE_DIRECT_HIT':
      return {
        ...state,
        licenseSearch: {
          ...state.licenseSearch,
          init: true,
          spinner: false,
          results: [],
          error: '',
        },
      };
    case 'LICENSE_SEARCH_ERROR':
      return {
        ...state,
        licenseSearch: {
          ...state.licenseSearch,
          init: false,
          spinner: false,
          results: [],
          error: action.payload,
        },
      };
    case 'LICENSE_PAGINATION_CHANGE':
      return {
        ...state,
        licenseSearch: {
          ...state.licenseSearch,
          pagination: {
            order: action.payload.order,
            orderBy: action.payload.orderBy,
            page: action.payload.page,
            rowsPerPage: action.payload.rowsPerPage,
          },
        },
      };
    case 'UPDATE_LICENSE_SEARCH_RESULTS':
      const newResults = state.licenseSearch.results.map((lic) =>
        lic.id === action.payload.id ? action.payload : lic
      );
      return {
        ...state,
        licenseSearch: {
          ...state.licenseSearch,
          results: newResults,
        },
      };
    case 'TRIGGER_LICENSE_SEARCH':
      if (action.payload.hasOwnProperty('searchBy')) {
        return {
          ...state,
          licenseSearch: {
            ...state.licenseSearch,
            init: false,
            spinner: true,
            results: [],
            error: '',
            searchBy: action.payload.searchBy,
            searchTerm: action.payload.searchTerm,
            searchTermLabel: action.payload.searchTermLabel,
            searchTermHelperText: action.payload.searchTermHelperText,
            pagination: {
              ...state.licenseSearch.pagination,
              page: 0,
            },
          },
        };
      } else {
        return {
          ...state,
          licenseSearch: {
            ...state.licenseSearch,
            pagination: {
              order: action.payload.order,
              orderBy: action.payload.orderBy,
              page: action.payload.page,
              rowsPerPage: action.payload.rowsPerPage,
            },
          },
        };
      }
    case 'OPEN_USER_SEARCH_DIALOG':
      return {
        ...state,
        licenseSearch: {
          ...state.licenseSearch,
          userSearchResults: action.payload.results,
          userSearchResultsDialogOpen: true,
        },
      };
    case 'TOGGLE_USER_SEARCH_DIALOG':
      return {
        ...state,
        licenseSearch: {
          ...state.licenseSearch,
          userSearchResultsDialogOpen: !state.licenseSearch.userSearchResultsDialogOpen,
        },
      };
    case 'LICENSE_USER_SELECTED':
      return {
        ...state,
        licenseSearch: {
          ...state.licenseSearch,
          userSearchResultsDialogOpen: false,
        },
      };
    case 'UPDATE_CREATE_LICENSE_STATE':
      return {
        ...state,
        createLicense: {
          ...state.createLicense,
          ...action.payload.formState,
        },
      };
    case 'INIT_CREATE_LICENSE_STATE':
      return {
        ...state,
        createLicense: createLicenseDefaults,
      };
    case 'SET_ORDER_ACTIVE_TAB':
      return {
        ...state,
        orderDetail: {
          ...state.orderDetail,
          activeTab: action.payload,
        },
      };
    case 'UPDATE_ORDER_SEARCH_STATE':
      return {
        ...state,
        orderSearch: {
          ...state.orderSearch,
          ...action.payload.formState,
        },
      };
    case 'ORDER_SEARCHING':
      return {
        ...state,
        orderSearch: {
          ...state.orderSearch,
          init: false,
          spinner: true,
          results: [],
          error: '',
          pagination: orderPagination,
          ...action.payload.formState,
        },
      };
    case 'ORDER_SEARCH_RESULTS_CHANGE':
      return {
        ...state,
        orderSearch: {
          ...state.orderSearch,
          init: false,
          spinner: false,
          results: action.payload.results,
          error: '',
        },
      };
    case 'ORDER_DIRECT_HIT':
      return {
        ...state,
        orderSearch: {
          ...state.orderSearch,
          init: true,
          spinner: false,
          results: [],
          error: '',
        },
      };
    case 'ORDER_SEARCH_ERROR':
      return {
        ...state,
        orderSearch: {
          ...state.orderSearch,
          init: false,
          spinner: false,
          results: [],
          error: action.payload.error,
        },
      };
    case 'ORDER_PAGINATION_CHANGE':
      return {
        ...state,
        orderSearch: {
          ...state.orderSearch,
          pagination: {
            order: action.payload.obj.order,
            orderBy: action.payload.obj.orderBy,
            page: action.payload.obj.page,
            rowsPerPage: action.payload.obj.rowsPerPage,
          },
        },
      };
    case 'UPDATE_ORGANIZATION_SEARCH_STATE':
      return {
        ...state,
        organizationSearch: {
          ...state.organizationSearch,
          ...action.payload.formState,
        },
      };
    case 'ORGANIZATION_SEARCHING':
      return {
        ...state,
        organizationSearch: {
          ...state.organizationSearch,
          init: false,
          spinner: true,
          results: [],
          error: '',
          searchTerm: action.payload.st,
          pagination: organizationPagination,
        },
      };
    case 'ORGANIZATION_SEARCH_RESULTS_CHANGE':
      return {
        ...state,
        organizationSearch: {
          ...state.organizationSearch,
          init: false,
          spinner: false,
          results: action.payload.results,
          error: '',
        },
      };
    case 'ORGANIZATION_DIRECT_HIT':
      return {
        ...state,
        organizationSearch: {
          ...state.organizationSearch,
          init: true,
          spinner: false,
          results: [],
          error: '',
        },
      };
    case 'ORGANIZATION_SEARCH_ERROR':
      return {
        ...state,
        organizationSearch: {
          ...state.organizationSearch,
          init: false,
          spinner: false,
          results: [],
          error: action.payload.error,
        },
      };
    case 'ORGANIZATION_PAGINATION_CHANGE':
      return {
        ...state,
        organizationSearch: {
          ...state.organizationSearch,
          pagination: {
            order: action.payload.obj.order,
            orderBy: action.payload.obj.orderBy,
            page: action.payload.obj.page,
            rowsPerPage: action.payload.obj.rowsPerPage,
          },
        },
      };
    case 'SET_ADMIN_ACTIVE_TAB':
      return {
        ...state,
        admin: {
          ...state.admin,
          activeTab: action.payload.idx,
        },
      };
    case 'UPDATE_AGENTLIST_STATE':
      return {
        ...state,
        agentList: {
          ...state.agentList,
          ...action.payload.state,
        },
      };
    case 'AGENTLIST_PAGINATION_CHANGE':
      return {
        ...state,
        agentList: {
          ...state.agentList,
          pagination: {
            order: action.payload.obj.order,
            orderBy: action.payload.obj.orderBy,
            page: action.payload.obj.page,
            rowsPerPage: action.payload.obj.rowsPerPage,
          },
        },
      };
    case 'UPDATE_USER_AUDITS_STATE':
      return {
        ...state,
        userAudits: {
          ...state.userAudits,
          ...action.payload.state,
        },
      };
    case 'UPDATE_USER_ROLES_STATE':
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          ...action.payload.state,
        },
      };
    default:
      return state;
  }
};

const Main = (props) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const { classes, instance } = props;

  useEffect(() => {
    // initialize user search state
    let field = state.userSearch.basicSearchField;
    field.searchTerm = '';
    field.searchTermLabel = _.find(userSearchCriteria, { value: field.value }).label;
    field.searchTermHelperText = _.find(userSearchCriteria, { value: field.value }).helperText;

    state.userSearch.advancedSearchFields.forEach((field) => {
      field.searchTerm = '';
      field.searchTermLabel = _.find(userSearchCriteria, { value: field.value }).label;
      field.searchTermHelperText = _.find(userSearchCriteria, { value: field.value }).helperText;
    });
  }, []);

  useEffect(() => {
    // set up tool selector in personView
    let tools = [];
    if (canView('identity') || canView('transactions') || canView('entitlements')) {
      if (canView('identity')) tools.push('Identity');
      if (canView('transactions')) tools.push('Transactions');
      if (canView('entitlements')) tools.push('Entitlements');
    } else {
      tools = canView('courses')
        ? ['Enrollments']
        : canView('telemetry')
        ? ['Telemetry']
        : canView('affiliations')
        ? ['Affiliations']
        : canView('identity')
        ? ['LMS']
        : canView('audit')
        ? ['Audit Trail']
        : [];
    }
    dispatch({ type: 'SELECTED_TOOLS', payload: tools });
  }, []);
  useEffect(() => {
    validateUser();
    loadCountries();
  }, []);

  // used in some searches. need to pass as property
  const handleBackdrop = (show) => {
    dispatch({ type: 'TOGGLE_BACKDROP', payload: show });
  };
  // callbacks from the original (pre leftnav) main component
  const userSelected = (user, key) => {
    dispatch({ type: 'SELECTED_USER', payload: { user: user, rowSelected: key } });
  };

  const updateUser = (user) => {
    dispatch({ type: 'UPDATE_USER', payload: user });
  };
  const courseSelected = (course, key) => {
    dispatch({ type: 'SELECTED_COURSE', payload: { course: course, rowSelected: key } });
  };
  const updateCourse = (course) => {
    dispatch({ type: 'UPDATE_COURSE', payload: course });
  };

  const productSelected = (product, key) => {
    dispatch({ type: 'SELECTED_PRODUCT', payload: { product: product, rowSelected: key } });
  };
  const updateProduct = (product) => {
    dispatch({ type: 'UPDATE_PRODUCT', payload: product });
  };

  const accessCodeSelected = (accessCode, key) => {
    dispatch({
      type: 'SELECTED_ACCESS_CODE',
      payload: { accessCode: accessCode, rowSelected: key },
    });
  };
  const updateAccessCode = (accessCode) => {
    dispatch({ type: 'UPDATE_ACCESS_CODE', payload: accessCode });
  };

  const licenseSelected = (lic, key) => {
    dispatch({
      type: 'SELECTED_LICENSE',
      payload: { license: lic, rowSelected: key },
    });
  };
  const updateLicense = (lic) => {
    dispatch({ type: 'UPDATE_LICENSE', payload: lic });
  };

  const orderSelected = (order, key) => {
    dispatch({
      type: 'SELECTED_ORDER',
      payload: { order: order, rowSelected: key },
    });
  };
  const updateOrder = (order) => {
    dispatch({ type: 'UPDATE_ORDER', payload: order });
  };

  const organizationSelected = (organization, key) => {
    dispatch({
      type: 'SELECTED_ORGANIZATION',
      payload: { organization: organization, rowSelected: key },
    });
  };
  const updateOrganization = (org) => {
    dispatch({ type: 'UPDATE_ORGANIZATION', payload: org });
  };

  //------------------------ PersonView (was ToolsView) ---

  const setSelectedTools = (tools) => {
    dispatch({ type: 'SELECTED_TOOLS', payload: tools });
  };
  const setSavedSelectedTools = (tools) => {
    dispatch({ type: 'SAVED_SELECTED_TOOLS', payload: tools });
  };
  const setPanelMaximized = (flag) => {
    dispatch({ type: 'PANEL_MAXIMIZED', payload: flag });
  };
  const setProductToSearch = (product) => {
    dispatch({ type: 'PRODUCT_TO_SEARCH', payload: product });
  };

  //----------------------USER ------------------------//

  const updateUserSearchState = (formState) => {
    dispatch({
      type: 'UPDATE_USER_SEARCH_STATE',
      payload: formState,
    });
  };
  const userSearching = (formState) => {
    dispatch({
      type: 'USER_SEARCHING',
      payload: formState,
    });
  };
  const userSearchResultsChange = (results, softError, totalElements) => {
    dispatch({
      type: 'USER_SEARCH_RESULTS_CHANGE',
      payload: { results, softError, totalElements },
    });
  };
  const userDirectHit = () => {
    dispatch({
      type: 'USER_DIRECT_HIT',
    });
  };
  const userSearchError = (error) => {
    dispatch({
      type: 'USER_SEARCH_ERROR',
      payload: error,
    });
  };
  const userPaginationChange = (obj) => {
    dispatch({
      type: 'USER_PAGINATION_CHANGE',
      payload: obj,
    });
  };

  // person search logic was moved here from PersonSearchForm to support backend pagination.
  // the PersonSearchResults needs to be able to trigger new searches when user wants to page.
  // this function either gets the initial search criteria object - or a pagination object.

  const triggerUserSearch = (obj) => {
    dispatch({
      type: 'TRIGGER_USER_SEARCH',
      payload: obj,
    });
  };

  const firstUpdate = useRef(true);
  const isAdvanced = useRef(false);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (isAdvanced.current) {
      advancedUserSearch();
    }
    else if (state.userSearch.basicSearchField) {
      basicUserSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.userSearch.pagination])

  // called once for basic search and from in a loop for advanced search
  const buildUserSearchPayload = (field) => {
    let payload = { [field.value]: field.searchTerm.trim() };

    switch (field.value) {
      case 'name':
        // parse the input into first and last name
        let arr = field.searchTerm.trim().split(' ');
        if (arr.length !== 2) {
          userSearchError('First and last names are required.');
          payload = null;
        } else {
          payload = { firstName: arr[0], lastName: arr[1] };
        }
        break;

      case 'phone':
        // parse number value into two parts
        let str = field.searchTerm.trim();
        let regexp = /^([+][1])([0-9]{10})$/;
        let res = str.match(regexp);
        if (!res) {
          userSearchError('Phone number format must be +11234567890.');
          payload = null;
        } else {
          payload = { phoneCountryCode: res[1], phoneNumber: res[2] };
        }
        break;

      case 'identityId':
        if (field.searchTerm.trim().split(' ').length !== 1) {
          userSearchError('Spaces are not allowed in an Identity ID.');
          payload = null;
        }
        break;

      case 'partialUserName':
        if (field.searchTerm.trim().length < 5 || field.searchTerm.trim().length > 10) {
          userSearchError('Partial username must be between 5 and 10 characters.');
          payload = null;
        } else {
          payload = { userName: field.searchTerm.trim(), containsSearch: true };
        }
        break;

      default:
        break;
    }

    return payload;
  };

  // dropdown value for basic search changed
  const userSearchByChange = (event) => {
    let criteriaConst = userSearchCriteria;
    let basicSearchField = state.userSearch.basicSearchField;
    basicSearchField.value = event.target.value;
    basicSearchField.searchTermLabel = criteriaConst.filter((o) => {
      return o.value === event.target.value;
    })[0].label;
    basicSearchField.searchTermHelperText = criteriaConst.filter((o) => {
      return o.value === event.target.value;
    })[0].helperText;
    dispatch({
      type: 'BASIC_SEARCH_FIELD',
      payload: basicSearchField,
    });

    updateUserSearchState(state.userSearch.basicSearchField);
  };

  // basic search term change
  const userSearchTermChange = (event) => {
    let basicSearchField = state.userSearch.basicSearchField;
    basicSearchField.searchTerm = event.target.value;
    dispatch({
      type: 'BASIC_SEARCH_FIELD',
      payload: basicSearchField,
    });
    updateUserSearchState(state.userSearch.basicSearchField);
  };

  const userAdvancedSearchTermChange = (event, field) => {
    let fields = state.userSearch.advancedSearchFields;
    let idx = _.findIndex(fields, { value: field });
    let obj = fields[idx];
    obj.searchTerm = event.target.value;

    fields.splice(idx, 1, obj);
    dispatch({
      type: 'ADVANCE_SEARCH_FIELD',
      payload: fields,
    });

    updateUserSearchState(state.userSearch.advancedSearchFields);
  };

  const basicUserSearch = (event) => {
    event && event.preventDefault();
    if (state.userSearch.basicSearchField.searchTerm.trim().length === 0) {
      userSearchError(`Please enter the ${state.userSearch.basicSearchField.searchTermLabel}.`);
      return null;
    }

    let payload = buildUserSearchPayload(state.userSearch.basicSearchField);
    if (!payload) {
      return null;
    }

    userSearch(payload);

    userSearching(state.userSearch.basicSearchField);
    isAdvanced.current = false;
  };

  const advancedUserSearch = (event) => {
    event && event.preventDefault(); // prevent page refresh

    let allFieldsEmpty = !state.userSearch.advancedSearchFields.some((field) => {
      return field.searchTerm.trim().length > 0;
    });
    if (allFieldsEmpty) {
      userSearchError(`Please enter at least one search term.`);
      return null;
    }

    let payload = {};
    let errorEncoungtered = false;
    let field;
    for (field of state.userSearch.advancedSearchFields) {
      if (field.searchTerm.trim().length > 0) {
        let prop = buildUserSearchPayload(field);
        if (!prop) {
          errorEncoungtered = true;
          break;
        }
        Object.assign(payload, prop);
      }
    }

    // errors could be displayed from buildPayload
    if (errorEncoungtered || Object.entries(payload).length === 0) {
      return null;
    }

    userSearch(payload);

    userSearching(state.userSearch.isAdvancedSearch);
    isAdvanced.current = true;
  };

  const userSearch = (payload) => {
    let url = `/api/identities/search?page=${state.userSearch.pagination.page}&size=${state.userSearch.pagination.rowsPerPage}`;
    axios
      .post(url, payload)
      .then((response) => {
        let [results, numFiltered] = Identity.parseResults(response.data.content);
        let totalElements = response.data.page.totalElements;
        if (results.length === 1) {
          let user = results[0];
          userSelected(user);
          setProductToSearch(null);
          displayDirectHitUser(user);
        } else {
          displayUserResults(results, numFiltered, totalElements);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          displayUserResults([]);
        } else {
          displayUserError(error);
        }
      });
  };

  const displayDirectHitUser = (user) => {
    props.navigate(`/person/${user.identityId}`);

    userDirectHit();
    let auditEvent = {
      resource: 'profile',
      action: 'view',
      resourceId: user.identityId,
    };
    recordAuditEvent(auditEvent);
  };

  const displayUserResults = (results, numFiltered, totalElements) => {
    if (numFiltered > 0) {
      let userStr = numFiltered === 1 ? 'user' : 'users';
      let error = `${numFiltered} ${userStr} could not be displayed due to country restrictions.`;
      userSearchResultsChange(results, error);
    } else {
      userSearchResultsChange(results, numFiltered, totalElements);
    }
  };

  const displayUserError = (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.status);
      console.dir(error.response.data);
      let err = error.response.data.details
        ? error.response.data.details[0]
        : error.response.data.message;
      userSearchError(err);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log('Request error. Likely timeout.');
      console.log(error.request);
      userSearchError('Request timed out.');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      userSearchError(error.message);
    }
  };

  //---------------------------------------------- COURSE ---

  const setCourseActiveTab = (idx) => {
    dispatch({ type: 'SET_COURSE_ACTIVE_TAB', payload: idx });
  };
  const updateCourseSearchState = (formState) => {
    dispatch({ type: 'UPDATE_COURSE_SEARCH_STATE', payload: formState });
  };
  const courseSearching = (sb, st, stl, stht) => {
    dispatch({
      type: 'COURSE_SEARCHING',
      payload: {
        searchBy: sb,
        searchTerm: st,
        searchTermLabel: stl,
        searchTermHelperText: stht,
        pagination: coursePagination,
      },
    });
  };
  const courseSearchResultsChange = (results, totalElements) => {
    dispatch({
      type: 'COURSE_SEARCH_RESULTS_CHANGE',
      payload: {
        results,
        totalElements,
      },
    });
  };
  const courseDirectHit = () => {
    dispatch({
      type: 'COURSE_DIRECT_HIT',
    });
  };
  const courseSearchError = (error) => {
    dispatch({
      type: 'COURSE_SEARCH_ERROR',
    });
  };
  const coursePaginationChange = (obj) => {
    dispatch({
      type: 'COURSE_PAGINATION_CHANGE',
      payload: obj,
    });
  };
  // course search logic was moved here from CourseSearchForm to support backend pagination.
  // the CourseSearchResults needs to be able to trigger new searches when user wants to page.
  // this function either gets the initial search criteria object - or a pagination object.
  const triggerCourseSearch = (obj) => {
    dispatch({
      type: 'TRIGGER_COURSE_SEARCH',
      payload: obj,
    });
  };

  const firstCourseSearch = useRef(true);

  useEffect(() => {
    if (firstCourseSearch.current) {
      firstCourseSearch.current = false;
      return;
    } else {
      courseSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.courseSearch.pagination]);

  const buildCourseSearchPayload = () => {
    if (state.courseSearch.searchTerm.trim().length === 0) {
      courseSearchError(`Please enter the ${state.courseSearch.searchTermLabel}.`);
      return null;
    }
    let payload = {
      [state.courseSearch.searchBy]: state.courseSearch.searchTerm,
      page: state.courseSearch.pagination.page.toString(),
      size: state.courseSearch.pagination.rowsPerPage.toString(),
    };

    return payload;
  };
  const courseSearch = () => {
    let payload = buildCourseSearchPayload(state.courseSearch);
    if (!payload) {
      return false;
    }
    handleBackdrop(true);

    let qs = createQueryString(payload);

    let url = `/api/course/search?${qs}`;
    axios
      .get(url)
      .then((response) => {
        handleBackdrop(false);
        let results = Course.parseResults(response.data.content);
        if (results.length === 1 && state.courseSearch.pagination.page === 0) {
          let course = results[0];
          courseSelected(course);
          displayDirectHitCourse(course);
        } else {
          displayCourseResults(results, response.data.page.totalElements);
        }
      })
      .catch((error) => {
        handleBackdrop(false);
        if (error.response && error.response.status === 404) {
          displayCourseResults([]);
        } else {
          displayCourseError(error);
        }
      });
  };
  const displayDirectHitCourse = (course) => {
    props.navigate(`/course/${course.id}`);

    courseDirectHit();
    let auditEvent = {
      resource: 'course',
      action: 'view',
      resourceId: course.id,
    };
    recordAuditEvent(auditEvent);
  };
  const displayCourseResults = (results, totalElements) => {
    courseSearchResultsChange(results, totalElements);
  };
  const displayCourseError = (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.status);
      console.dir(error.response.data);
      let err = error.response.data.details
        ? error.response.data.details[0]
        : error.response.data.message;
      courseSearchError(err);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log('Request error. Likely timeout.');
      console.log(error.request);
      courseSearchError('Request timed out.');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      courseSearchError(error.message);
    }
  };

  //---------------------------------------------- PRODUCT ---

  const setProductActiveTab = (idx) => {
    dispatch({ type: 'SET_PRODUCT_ACTIVE_TAB', payload: idx });
  };
  const updateProductSearchState = (formState) => {
    dispatch({ type: 'UPDATE_PRODUCT_SEARCH_STATE', payload: formState });
  };
  const productSearching = (formState) => {
    dispatch({ type: 'PRODUCT_SEARCHING', payload: formState });
  };
  const productSearchResultsChange = (results) => {
    dispatch({ type: 'PRODUCT_SEARCH_RESULTS_CHANGE', payload: results });
  };
  const productDirectHit = () => {
    dispatch({ type: 'PRODUCT_DIRECT_HIT' });
  };
  const productSearchError = (error) => {
    dispatch({ type: 'PRODUCT_SEARCH_ERROR', payload: error });
  };
  const productPaginationChange = (obj) => {
    dispatch({ type: 'PRODUCT_PAGINATION_CHANGE', payload: obj });
  };

  //---------------------------------------------- ACCESS CODE ---

  const setAccessCodeActiveTab = (idx) => {
    dispatch({ type: 'SET_ACCESS_CODE_ACTIVE_TAB', payload: idx });
  };
  const accessCodeSearching = (accessCode) => {
    dispatch({ type: 'ACCESS_CODE_SEARCHING', payload: accessCode });
  };
  const accessCodeSearchResultsChange = (results) => {
    dispatch({ type: 'ACCESS_CODE_SEARCH_RESULTS_CHANGE', payload: results });
  };
  const accessCodeDirectHit = () => {
    dispatch({ type: 'ACCESS_CODE_DIRECT_HIT' });
  };
  const accessCodeSearchError = (error) => {
    dispatch({ type: 'ACCESS_CODE_SEARCH_ERROR', payload: error });
  };
  const accessCodePaginationChange = (obj) => {
    dispatch({ type: 'ACCESS_CODE_PAGINATION_CHANGE', payload: obj });
  };

  //---------------------------------------------- LICENSE ---
  const setLicenseActiveTab = (idx) => {
    dispatch({ type: 'SET_LICENSE_ACTIVE_TAB', payload: idx });
  };
  const setOrgActiveTab = (idx) => {
    dispatch({ type: 'SET_ORG_ACTIVE_TAB', payload: idx });
  };
  const updateLicenseSearchState = (formState) => {
    dispatch({ type: 'UPDATE_LICENSE_SEARCH_STATE', payload: formState });
  };
  const licenseSearching = (sb, st, stl, stht) => {
    dispatch({
      type: 'LICENSE_SEARCHING',
      payload: { searchBy: sb, searchTerm: st, searchTermLabel: stl, searchTermHelperText: stht },
    });
  };
  const licenseSearchResultsChange = (results, totalElements) => {
    dispatch({ type: 'LICENSE_SEARCH_RESULTS_CHANGE', payload: { results, totalElements } });
  };
  const updateLicenseSearchResults = (license) => {
    dispatch({ type: 'UPDATE_LICENSE_SEARCH_RESULTS', payload: license });
  };
  const licenseDirectHit = () => {
    dispatch({ type: 'LICENSE_DIRECT_HIT' });
  };
  const licenseSearchError = (error) => {
    dispatch({ type: 'LICENSE_SEARCH_ERROR', payload: error });
  };
  const licensePaginationChange = (obj) => {
    dispatch({ type: 'LICENSE_PAGINATION_CHANGE', payload: obj });
  };

  const displayLicenseResults = (results, totalElements) => {
    licenseSearchResultsChange(results, totalElements);
  };

  // license search logic was moved here from LicenseSearchForm to support backend pagination.
  // the LicenseSearchResults needs to be able to trigger new searches when user wants to page.
  // this function either gets the initial search criteria object - or a pagination object.
  const triggerLicenseSearch = (obj) => {
    dispatch({ type: 'TRIGGER_LICENSE_SEARCH', payload: obj });
  };

  const firstLicenseSearch = useRef(true);
  const payloadAcquired = useRef(false);

  useEffect(() => {
    payloadAcquired.current = false;
  }, [state.licenseSearch.searchTerm]);

  useEffect(() => {
    if (firstLicenseSearch.current) {
      firstLicenseSearch.current = false;
      return;
    } else if (haveIdentityCriteria() && payloadAcquired.current) {
      let payload = {
        ownerId: state.licenseSearch.payload.ownerId,
        page: state.licenseSearch.pagination.page.toString(),
        size: state.licenseSearch.pagination.rowsPerPage.toString(),
      }
      licenseSearchWithPayload(payload);
    }
    else {
      licenseSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.licenseSearch.pagination]);

  const haveIdentityCriteria = () => {
    return ['userName', 'name', 'email'].indexOf(state.licenseSearch.searchBy) !== -1;
  };

  const buildLicenseSearchPayload = () => {
    if (state.licenseSearch.searchTerm.trim().length === 0) {
      licenseSearchError(`Please enter the ${state.licenseSearch.searchTermLabel}.`);
      return null;
    }

    let payload = {
      [state.licenseSearch.searchBy]: state.licenseSearch.searchTerm.trim(),
      page: state.licenseSearch.pagination.page.toString(),
      size: state.licenseSearch.pagination.rowsPerPage.toString(),
    };

    return payload;
  };

  const licenseSearch = () => {
    let payload = buildLicenseSearchPayload(state.licenseSearch);
    
    if (!payload) {
      return false;
    }

    if (payload.id) {
      displayDirectHitLicense(payload.id);
    } else if (haveIdentityCriteria() && !payloadAcquired.current) {
      if (state.licenseSearch.searchBy === 'name') {
        // parse the input into first and last name
        let arr = state.licenseSearch.searchTerm.trim().split(' ');
        if (arr.length !== 2) {
          licenseSearchError('First and Last names are required.');
          return false;
        } else {
          payload = { firstName: arr[0], lastName: arr[1] };
        }
      }
      identitySearch(payload);
    } else {
      licenseSearchWithPayload(payload);
    }
    payloadAcquired.current = true;
  };

  const licenseSearchWithPayload = (payload) => {
    handleBackdrop(true);
    let qs = createQueryString(payload);
    let url = '/api/licenses/search?enhanced=true&' + qs;
    axios
      .get(url)
      .then((response) => {
        handleBackdrop(false); // for when initiated by userSearchDialog
        // setState(
        //   response.data.content && response.data.content.length > 0
        //     ? { found: true }
        //     : { found: false }
        // );
        let results = License.parseResults(response.data.content);
        if (results.length === 1 && state.licenseSearch.pagination.page === 0) {
          let license = results[0];
          licenseSelected(license);
          displayDirectHitLicense(license.id);
        } else {
          displayLicenseResults(results, response.data.page.totalElements);
        }
      })
      .catch((error) => {
        handleBackdrop(false);
        if (error.response && error.response.status === 404) {
          displayLicenseResults([], 0);
        } else if (error.response && error.response.status === 504) {
          displayLicenseError({ message: 'Request timed out' });
        } else {
          displayLicenseError(error);
        }
      });
  };

  const displayDirectHitLicense = (id) => {
    let licensePlusPlus = {};
    handleBackdrop(true);
    let url = '/api/licenses/' + id;
    axios
      .get(url)
      .then((response) => {
        handleBackdrop(false);
        licensePlusPlus = License.parseResults(response.data);
        licenseSelected(licensePlusPlus);
        props.navigate(`/license/${licensePlusPlus.id}`);
        licenseDirectHit();
      })
      .catch((error) => {
        handleBackdrop(false);
        displayLicenseError(error);
      });

    let auditEvent = {
      resource: 'license',
      action: 'view',
      resourceId: id,
    };
    recordAuditEvent(auditEvent);
  };

  const displayLicenseError = (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      let err = error.response.data.details
        ? error.response.data.details[0]
        : error.response.data.message;
      licenseSearchError(err);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      licenseSearchError('Request timed out.');
    } else {
      // Something happened in setting up the request that triggered an Error
      licenseSearchError(error.message);
    }
  };

  const identitySearch = (payload) => {
    let url = '/api/identities/search';
    axios
      .post(url, payload)
      .then((response) => {
        let [results] = Identity.parseResults(response.data.content);
        if (results.length === 0) {
          displayLicenseResults([], 0);
        } else if (results.length === 1) {
          let payload = {
            ownerId: results[0].identityId,
            page: state.licenseSearch.pagination.page.toString(),
            size: state.licenseSearch.pagination.rowsPerPage.toString(),
          };
          toggleLicenseIdSearch(payload);
          licenseSearchWithPayload(payload);
        } else {
          openUserSearchDialog(results);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          displayLicenseResults([], 0);
        } else {
          displayLicenseError(error);
        }
      });
  };

  const toggleLicenseIdSearch = (results) => {
    dispatch({ type: 'LICENSE_PAYLOAD_FOUND', payload: { results } });
  }

  const openUserSearchDialog = (results) => {
    dispatch({ type: 'OPEN_USER_SEARCH_DIALOG', payload: { results } });
  };

  const closeUserSearchDialog = () => {
    dispatch({ type: 'TOGGLE_USER_SEARCH_DIALOG' });
    licenseSearchError('Search canceled.');
  };

  const licenseUserSelected = (user) => {
    let payload = {
      ownerId: user.identityId,
      page: state.licenseSearch.pagination.page.toString(),
      size: state.licenseSearch.pagination.rowsPerPage.toString(),
    };

    toggleLicenseIdSearch(payload);
    dispatch({ type: 'TOGGLE_USER_SEARCH_DIALOG' });
    licenseSearchWithPayload(payload);
  };

  const updateCreateLicenseState = (formState) => {
    dispatch({ type: 'UPDATE_CREATE_LICENSE_STATE', payload: { formState } });
  };
  const initCreateLicenseState = () => {
    dispatch({ type: 'INIT_CREATE_LICENSE_STATE' });
  };

  //---------------------------------------------- ORDER ---

  const setOrderActiveTab = (idx) => {
    dispatch({ type: 'SET_ORDER_ACTIVE_TAB', payload: idx });
  };
  const updateOrderSearchState = (formState) => {
    dispatch({ type: 'UPDATE_ORDER_SEARCH_STATE', payload: { formState } });
  };
  const orderSearching = (formState) => {
    dispatch({ type: 'ORDER_SEARCHING', payload: { formState } });
  };
  const orderSearchResultsChange = (results) => {
    dispatch({ type: 'ORDER_SEARCH_RESULTS_CHANGE', payload: { results } });
  };
  const orderDirectHit = () => {
    dispatch({ type: 'ORDER_DIRECT_HIT' });
  };
  const orderSearchError = (error) => {
    dispatch({ type: 'ORDER_SEARCH_ERROR', payload: { error } });
  };
  const orderPaginationChange = (obj) => {
    dispatch({ type: 'ORDER_PAGINATION_CHANGE', payload: { obj } });
  };

  //---------------------------------------------- ORGANIZATION ---

  const updateOrganizationSearchState = (formState) => {
    dispatch({ type: 'UPDATE_ORGANIZATION_SEARCH_STATE', payload: { formState } });
  };
  const organizationSearching = (st) => {
    dispatch({ type: 'ORGANIZATION_SEARCHING', payload: { st } });
  };
  const organizationSearchResultsChange = (results) => {
    dispatch({ type: 'ORGANIZATION_SEARCH_RESULTS_CHANGE', payload: { results } });
  };
  const organizationDirectHit = () => {
    dispatch({ type: 'ORGANIZATION_DIRECT_HIT' });
  };
  const organizationSearchError = (error) => {
    dispatch({ type: 'ORGANIZATION_SEARCH_ERROR', payload: { error } });
  };
  const organizationPaginationChange = (obj) => {
    dispatch({ type: 'ORGANIZATION_PAGINATION_CHANGE', payload: { obj } });
  };

  //---------------------------------------------- ADMIN ---

  const setAdminActiveTab = (idx) => {
    dispatch({ type: 'SET_ADMIN_ACTIVE_TAB', payload: { idx } });
  };

  const updateAgentlistState = (state) => {
    dispatch({ type: 'UPDATE_AGENTLIST_STATE', payload: { state } });
  };

  const agentListPaginationChange = (obj) => {
    dispatch({ type: 'AGENTLIST_PAGINATION_CHANGE', payload: { obj } });
  };

  const updateUserAuditsState = (state) => {
    dispatch({ type: 'UPDATE_USER_AUDITS_STATE', payload: { state } });
  };

  const updateUserRolesState = (state) => {
    dispatch({ type: 'UPDATE_USER_ROLES_STATE', payload: { state } });
  };

  return (
    <>
      <Suspense fallback={null}>
        <Banner instance={instance} />
        <Backdrop className={classes.backdrop} open={state.showBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Routes>
          <Route
            path="/"
            element={
              <LeftNav
                user={state.user}
                course={state.course}
                product={state.product}
                accessCode={state.accessCode}
                license={state.license}
                organization={state.organization}
                order={state.order}
              />
            }>
            <Route
              path="/person"
              element={
                <PersonSearch
                  userSearchCriteria={userSearchCriteria}
                  userSelected={userSelected}
                  updateUser={updateUser}
                  updateUserSearchState={updateUserSearchState}
                  basicSearchField={state.userSearch.basicSearchField}
                  advancedSearchFields={state.userSearch.advancedSearchFields}
                  isAdvancedSearch={state.userSearch.isAdvancedSearch}
                  userSearchByChange={userSearchByChange}
                  userSearchTermChange={userSearchTermChange}
                  userAdvancedSearchTermChange={userAdvancedSearchTermChange}
                  basicUserSearch={basicUserSearch}
                  advancedUserSearch={advancedUserSearch}
                  searchByChange={userSearchByChange}
                  searchTermChange={userSearchTermChange}
                  advancedSearchTermChange={userAdvancedSearchTermChange}
                  triggerUserSearch={triggerUserSearch}
                  init={state.userSearch.init}
                  spinner={state.userSearch.spinner}
                  results={state.userSearch.results}
                  rowSelected={state.userSearch.rowSelected}
                  error={state.userSearch.error}
                  softError={state.userSearch.softError}
                  order={state.userSearch.pagination.order}
                  orderBy={state.userSearch.pagination.orderBy}
                  page={state.userSearch.pagination.page}
                  rowsPerPage={state.userSearch.pagination.rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  savePaginationState={userPaginationChange}
                  totalElements={state.userSearch.totalElements}
                />
              }
            />

            <Route
              path="/person/:identityId"
              element={
                <PersonDetails
                  user={state.user}
                  userSelected={userSelected}
                  updateUser={updateUser}
                  selectedTools={state.selectedTools}
                  savedSelectedTools={state.savedSelectedTools}
                  setSelectedTools={setSelectedTools}
                  productToSearch={state.productToSearch}
                  setProductToSearch={setProductToSearch}
                  setSavedSelectedTools={setSavedSelectedTools}
                  panelMaximized={state.panelMaximized}
                  setPanelMaximized={setPanelMaximized}
                />
              }
            />

            <Route
              path="course"
              element={
                <CourseSearch
                  courseSearchCriteria={courseSearchCriteria}
                  courseSelected={courseSelected}
                  updateCourse={updateCourse}
                  updateCourseSearchState={updateCourseSearchState}
                  courseSearching={courseSearching}
                  courseSearchResultsChange={courseSearchResultsChange}
                  courseDirectHit={courseDirectHit}
                  courseSearchError={courseSearchError}
                  searchBy={state.courseSearch.searchBy}
                  searchTerm={state.courseSearch.searchTerm}
                  searchTermLabel={state.courseSearch.searchTermLabel}
                  searchTermHelperText={state.courseSearch.searchTermHelperText}
                  triggerCourseSearch={triggerCourseSearch}
                  init={state.courseSearch.init}
                  spinner={state.courseSearch.spinner}
                  results={state.courseSearch.results}
                  rowSelected={state.courseSearch.rowSelected}
                  error={state.courseSearch.error}
                  softError={state.courseSearch.softError}
                  order={state.courseSearch.pagination.order}
                  orderBy={state.courseSearch.pagination.orderBy}
                  page={state.courseSearch.pagination.page}
                  rowsPerPage={state.courseSearch.pagination.rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  savePaginationState={coursePaginationChange}
                  totalElements={state.courseSearch.totalElements}
                />
              }
            />
            <Route
              path="course/:courseId"
              element={
                <CourseDetails
                  course={state.course}
                  courseSelected={courseSelected}
                  onDirectHit={courseDirectHit}
                  updateCourse={updateCourse}
                  activeTab={state.courseDetails.activeTab}
                  setCourseActiveTab={setCourseActiveTab}
                  setSelectedTools={setSelectedTools}
                  user={state.user}
                />
              }
            />

            <Route
              path="product"
              element={
                <ProductSearch
                  productSearchCriteria={productSearchCriteria}
                  updateProductSearchState={updateProductSearchState}
                  productSearching={productSearching}
                  productSearchError={productSearchError}
                  productSearchResultsChange={productSearchResultsChange}
                  productDirectHit={productDirectHit}
                  productSelected={productSelected}
                  searchBy={state.productSearch.searchBy}
                  searchTerm={state.productSearch.searchTerm}
                  searchTermLabel={state.productSearch.searchTermLabel}
                  searchTermHelperText={state.productSearch.searchTermHelperText}
                  showBackdrop={handleBackdrop}
                  init={state.productSearch.init}
                  spinner={state.productSearch.spinner}
                  results={state.productSearch.results}
                  rowSelected={state.productSearch.rowSelected}
                  error={state.productSearch.error}
                  // updateProductSearchResults={updateProductSearchResults}
                  updateProduct={updateProduct}
                  order={state.productSearch.pagination.order}
                  orderBy={state.productSearch.pagination.orderBy}
                  page={state.productSearch.pagination.page}
                  rowsPerPage={state.productSearch.pagination.rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  savePaginationState={productPaginationChange}
                />
              }
            />
            <Route
              path="product/:productId"
              element={
                <ProductDetails
                  product={state.product}
                  updateProduct={updateProduct}
                  productSelected={productSelected}
                  onDirectHit={productDirectHit}
                  setProductActiveTab={setProductActiveTab}
                  activeTab={state.productDetails.activeTab}
                  setSelectedTools={setSelectedTools}
                  setProductToSearch={setProductToSearch}
                />
              }
            />

            <Route
              path="accessCode"
              element={
                <AccessCodeSearch
                  accessCodeSearching={accessCodeSearching}
                  accessCodeSearchError={accessCodeSearchError}
                  accessCodeSearchResultsChange={accessCodeSearchResultsChange}
                  accessCodeDirectHit={accessCodeDirectHit}
                  accessCode={state.accessCodeSearch.accessCode}
                  accessCodeSelected={accessCodeSelected}
                  init={state.accessCodeSearch.init}
                  spinner={state.accessCodeSearch.spinner}
                  results={state.accessCodeSearch.results}
                  rowSelected={state.accessCodeSearch.rowSelected}
                  error={state.accessCodeSearch.error}
                  order={state.accessCodeSearch.pagination.order}
                  orderBy={state.accessCodeSearch.pagination.orderBy}
                  page={state.accessCodeSearch.pagination.page}
                  rowsPerPage={state.accessCodeSearch.pagination.rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  savePaginationState={accessCodePaginationChange}
                />
              }
            />
            <Route
              path="accessCode/:accessCode"
              element={
                <AccessCodeDetails
                  accessCode={state.accessCode}
                  updateAccessCode={updateAccessCode}
                  accessCodeSelected={accessCodeSelected}
                  onDirectHit={accessCodeDirectHit}
                  activeTab={state.accessCodeDetails.activeTab}
                  setAccessCodeActiveTab={setAccessCodeActiveTab}
                  userSelected={userSelected}
                  setSelectedTools={setSelectedTools}
                />
              }
            />
            <Route path="accessCode/generator" element={<AccessCodeGenerator />} />

            <Route
              path="license"
              element={
                <LicenseSearch
                  licenseSearchCriteria={licenseSearchCriteria}
                  updateLicenseSearchState={updateLicenseSearchState}
                  licenseSearching={licenseSearching}
                  licenseSearchError={licenseSearchError}
                  licenseSearchResultsChange={licenseSearchResultsChange}
                  licenseDirectHit={licenseDirectHit}
                  licenseSelected={licenseSelected}
                  searchBy={state.licenseSearch.searchBy}
                  searchTerm={state.licenseSearch.searchTerm}
                  searchTermLabel={state.licenseSearch.searchTermLabel}
                  searchTermHelperText={state.licenseSearch.searchTermHelperText}
                  showBackdrop={handleBackdrop}
                  triggerLicenseSearch={triggerLicenseSearch}
                  licenseUserSelected={licenseUserSelected}
                  userSearchResults={state.licenseSearch.userSearchResults}
                  userSearchResultsDialogOpen={state.licenseSearch.userSearchResultsDialogOpen}
                  closeUserSearchDialog={closeUserSearchDialog}
                  init={state.licenseSearch.init}
                  spinner={state.licenseSearch.spinner}
                  results={state.licenseSearch.results}
                  rowSelected={state.licenseSearch.rowSelected}
                  error={state.licenseSearch.error}
                  updateLicenseSearchResults={updateLicenseSearchResults}
                  updateLicense={updateLicense}
                  order={state.licenseSearch.pagination.order}
                  orderBy={state.licenseSearch.pagination.orderBy}
                  page={state.licenseSearch.pagination.page}
                  rowsPerPage={state.licenseSearch.pagination.rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  savePaginationState={licensePaginationChange}
                  totalElements={state.licenseSearch.totalElements}
                />
              }
            />
            <Route
              path="license/:licenseId"
              element={
                <LicenseDetails
                  license={state.license}
                  updateLicense={updateLicense}
                  licenseSelected={licenseSelected}
                  onDirectHit={licenseDirectHit}
                  activeTab={state.licenseDetails.activeTab}
                  setLicenseActiveTab={setLicenseActiveTab}
                />
              }
            />
            <Route
              path="license/generator"
              element={
                <CreateLicense
                  createLicense={state.createLicense}
                  updateCreateLicenseState={updateCreateLicenseState}
                  initCreateLicenseState={initCreateLicenseState}
                  licenseSelected={licenseSelected}
                  showBackdrop={handleBackdrop}
                />
              }
            />

            <Route
              path="order"
              element={
                <OrderSearch
                  orderSearchCriteria={orderSearchCriteria}
                  updateOrderSearchState={updateOrderSearchState}
                  orderSearching={orderSearching}
                  orderSearchError={orderSearchError}
                  orderSearchResultsChange={orderSearchResultsChange}
                  orderDirectHit={orderDirectHit}
                  orderSelected={orderSelected}
                  searchBy={state.orderSearch.searchBy}
                  searchTerm={state.orderSearch.searchTerm}
                  searchTermLabel={state.orderSearch.searchTermLabel}
                  searchTermHelperText={state.orderSearch.searchTermHelperText}
                  showBackdrop={handleBackdrop}
                  init={state.orderSearch.init}
                  spinner={state.orderSearch.spinner}
                  results={state.orderSearch.results}
                  rowSelected={state.orderSearch.rowSelected}
                  error={state.orderSearch.error}
                  // updateOrderSearchResults={updateOrderSearchResults}
                  updateOrder={updateOrder}
                  order={state.orderSearch.pagination.order}
                  orderBy={state.orderSearch.pagination.orderBy}
                  page={state.orderSearch.pagination.page}
                  rowsPerPage={state.orderSearch.pagination.rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  savePaginationState={orderPaginationChange}
                />
              }
            />
            <Route
              path="order/:orderId"
              element={
                <OrderDetails
                  order={state.order}
                  updateOrder={updateOrder}
                  orderSelected={orderSelected}
                  onDirectHit={orderDirectHit}
                  setOrderActiveTab={setOrderActiveTab}
                  activeTab={state.orderDetail.activeTab}
                  setSelectedTools={setSelectedTools}
                  setProductToSearch={setProductToSearch}
                />
              }
            />
            <Route
              path="organization"
              element={
                <OrganizationSearch
                  updateOrganizationSearchState={updateOrganizationSearchState}
                  organizationSearching={organizationSearching}
                  organizationSearchError={organizationSearchError}
                  organizationSearchResultsChange={organizationSearchResultsChange}
                  organizationDirectHit={organizationDirectHit}
                  organizationSelected={organizationSelected}
                  searchTerm={state.organizationSearch.searchTerm}
                  showBackdrop={handleBackdrop}
                  init={state.organizationSearch.init}
                  spinner={state.organizationSearch.spinner}
                  results={state.organizationSearch.results}
                  rowSelected={state.organizationSearch.rowSelected}
                  error={state.organizationSearch.error}
                  updateOrganization={updateOrganization}
                  order={state.organizationSearch.pagination.order}
                  orderBy={state.organizationSearch.pagination.orderBy}
                  page={state.organizationSearch.pagination.page}
                  rowsPerPage={state.organizationSearch.pagination.rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  savePaginationState={organizationPaginationChange}
                />
              }
            />
            <Route
              path="organization/:organizationId"
              element={
                <OrganizationDetails
                  organization={state.organization}
                  organizationSelected={organizationSelected}
                  onDirectHit={organizationDirectHit}
                  setOrgActiveTab={setOrgActiveTab}
                  activeTab={state.OrgDetails.activeTab}
                />
              }
            />

            <Route
              path="admin"
              element={
                <AdminDashboard
                  activeTab={state.admin.activeTab}
                  setAdminActiveTab={setAdminActiveTab}
                  agentListLoading={state.agentList.loading}
                  agentListResults={state.agentList.results}
                  agentListSearchTerm={state.agentList.searchTerm}
                  agentListPagination={state.agentList.pagination}
                  agentListPaginationChange={agentListPaginationChange}
                  updateAgentlistState={updateAgentlistState}
                  rowsPerPageOptions={rowsPerPageOptions}
                  userAuditsSearchTerm={state.userAudits.searchTerm}
                  userAuditsStartDate={state.userAudits.startDate}
                  userAuditsEndDate={state.userAudits.endDate}
                  updateUserAuditsState={updateUserAuditsState}
                  userRolesSearchTerm={state.userRoles.searchTerm}
                  updateUserRolesState={updateUserRolesState}
                  agentListTotalElements={state.agentList.totalElements}
                />
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

Main.propTypes = {
  classes: PropTypes.object.isRequired,
  instance: PropTypes.object,
};

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

const mainHOC = withStyles(styles, { withTheme: true })(Main);
export default withNavigation(mainHOC);

function AccessCodeGenerator() {
  return (
    <div>
      <h2 style={{ padding: '10px 40px' }}>Access Code Generator</h2>
    </div>
  );
}
