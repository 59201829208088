import axios from 'axios';
import moment from 'moment';

export const dateDefaultFormat = "YYYY-MM-DD[T]HH:mm:ss[.]SSS[Z]";
export const dateDisplayFormat = "YYYY-MM-DD HH:mm [UTC]";
export const dateDisplayFormatNoTimezone = "YYYY-MM-DD HH:mm";
export const dateDisplayFormatNoTimezoneNoTime = "YYYY-MM-DD";
export const entitlementUrl = '/api/entitlements';

export const getTimeUTC = (dateStr) => {
  return moment.utc(dateStr).format(dateDisplayFormat);
}

export const getTimeNoUTC = (dateStr) => {
  return moment.utc(dateStr).format(dateDisplayFormatNoTimezone);
}

export const getTimeNoUTCNoTime = (dateStr) => {
  return moment.utc(dateStr).format(dateDisplayFormatNoTimezoneNoTime);
}

export const getTime = (dateStr) => {
  return moment.utc(dateStr).format('HH:mm');
}

// {
//     "agent": "testUser",
//     "resourceId" "joeStudent",
//     "resource": "roster",
//     "action": "view",
//     "eventData": {
//         "source": "CURRENT_GEN",
//         "courseId": "testCourseId",
//         "courseName": "Test Course 101"
//     }
// }  
export const recordAuditEvent = (auditEvent) => {
  axios({
    url: '/api/audit',
    method: 'POST',
    data: auditEvent
  }) 
    .then(response => {
      // console.log('recordAuditEvent returns: ', response);
    })
    .catch(error => {
      console.log('recordAuditEvent error: ', error.response);
    });    
  }

export const valueFrom = (key, source, def) => {
  let o = source;
  const path = key.split('.');
  let segment;
  while (o && path.length) {
    segment = path.shift();
    o = o[segment];
  }
  if (typeof (o) !== 'undefined') {
    return o;
  }

  return def;
};

export const setObjectValue = (source, key, value) => {
  let o = source;
  const path = key.split('.');
  let last;
  let segment;

  while (o && path.length) {
    segment = path.shift();
    last = o;
    o = o[segment];
    if (!o) {
      o = last[segment] = {};
    }
  }

  last[segment] = value;
  return source;
};

export const createQueryString = (obj) => {
  var str = [];
  for (var p in obj) {
    // if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
    if (obj.hasOwnProperty(p) && obj[p]) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.join("&");
};

export const decodeHtml = (str) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = str;
  return txt.value;
}

export const commaSeparate = (arr) => {
  return !arr ? 'none' :  
         arr.length > 0 ? arr.join(', ') :
         'N/A';
}

export const getError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.status);
    console.dir(error.response.data);
    if (error.response.data.details) {
      return error.response.data.details[0];
    } else {
      return error.response.data.message;
    }

  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log('Request error. Likely timeout.');
    console.log(error.request);
    return 'Request timed out.';
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error: ', error.message);
    return error.message;
  }
}