import axios from 'axios';
import Config from './config';
import { loginRequest } from './authConfig';

const config = new Config();
let timer = null;



const U42_BASE_URL = window.location.origin + '/';
const U42_INTERVAL = config.get('U42.INTERVAL');

export const signInUrl = () => {
  return `${U42_BASE_URL}`;
};

export const signOutUrl = () => {
  return `${U42_BASE_URL}`;
};

export const redirectToSignin = () => {
  window.location.href = signInUrl(window.location.origin + '/');
};

export const getAccessToken = (msalInstance) => {
  msalInstance.acquireTokenSilent({
    ...loginRequest, 
    account: msalInstance.getActiveAccount(),})
    .then((accessTokenResponse) => {
      //Save token in U42 config
      const bearer = `Bearer ${accessTokenResponse.accessToken}`;
      config.set('U42.TOKEN', bearer);
      config.set('U42.TOKEN_EXPIRES_ON', accessTokenResponse.expiresOn );
      axios.defaults.headers.common = { 'Authorization': config.get('U42.TOKEN')};
    })
    .catch (err => {
      //Acquire token silent failure
      console.log("Acquire token silent failure :" + err);
      const currentAccount = msalInstance.getActiveAccount();
      msalInstance.logoutRedirect({ account: currentAccount, postLogoutRedirectUri: window.location.origin});
    });
}

export const signOut = (instance) => {
  clearInterval(timer);

  const currentAccount = instance.getActiveAccount();
  instance.logoutRedirect({ account: currentAccount, postLogoutRedirectUri: window.location.origin});
};

function validateToken (token, msalInstance) {
  if (!config.get('U42.AUTHORIZED')) {
    return;
  }
  if (!token) {
    token = config.get('U42.TOKEN');
  }

  if (token) {
    let expiresOn = config.get('U42.TOKEN_EXPIRES_ON');
    var date = new Date();
    let seconds = Math.abs(expiresOn.getTime() - date.getTime())/1000;
    if (seconds < 300){
      getAccessToken(msalInstance);
      axios.get('/api/validate/'
      )
      .then(response => {
        if (!response.data.valid) {
          getAccessToken(msalInstance);
        }
      })
      .catch(error => {
        console.log('validate error: ', error);
        getAccessToken(msalInstance);
      });
    }
  } else {
    getAccessToken(msalInstance);
  }
}

 export const validateUser = () => {
  axios.get('/api/validate/').then(resp=> {
    if(resp.data) {
      config.set('U42.UID',resp.data.uid);
      axios({
        url: '/api/dashboardUser',
        method: 'POST',
        data: {userId : resp.data.uid}
      }).then(response => {
        }).catch(error => {
        });
    }
  }).catch(error => {
    console.log('Error validating user ', error);
  });
}

const validate = (config, msalInstance, callback) => {
  let token = config.get('U42.TOKEN');
  timer = setInterval(validateToken, U42_INTERVAL, token, msalInstance);
  callback();
}

export default validate;
