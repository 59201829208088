import axios from 'axios';

let _countries = [];

export const loadCountries = () => {
  let url = '/api/countries';
  axios.get(url
    )
    .then(response => {
      _countries = response.data.data;
    })
    .catch(error => {
      console.log('error loading countries: ', error);
    });
}

export const getCountries = () => {
  return _countries;
}